<template>
  <div id="APR">
    <!-- HERO
    ================================================== -->
    <section class="section section-top">
      <!-- Cover -->
      <div class="bg-cover" style="background-color: #F0CD5E;"></div>

      <!-- Overlay -->
      <!-- <div class="bg-overlay"></div> -->

      <!-- Triangles -->
      <!-- <div class="bg-triangle bg-triangle-light bg-triangle-bottom bg-triangle-left"></div> -->
      <!-- <div class="bg-triangle bg-triangle-light bg-triangle-bottom bg-triangle-right"></div>  -->

      <!-- Content -->
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-md-8 col-lg-7">
            <!-- Preheading -->
            <p
              class="font-weight-medium text-center text-xl text-uppercase text-black"
            >
              <img src="../assets/logo_bw_mr.svg" />
            </p>

            <!-- Heading -->
            <h1 class="text-black text-center mb-4">
              market reasearch & online reviews
            </h1>

            <!-- Subheading -->
            <p class="lead text-black text-center mb-5">
              Keep track of your competition. Monitor your market segment.
            </p>

            <!-- Button -->
            <p class="text-center mb-0">
              <router-link
                :to="`/contact`"
                class="btn btn-outline-dark text-black"
              >
                contact us
              </router-link>
            </p>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>

    <!-- SECTIONS
    ================================================== -->
    <section class="section bg-light">
      <!-- Triangle -->
      <div
        class="bg-triangle bg-triangle-dark bg-triangle-top bg-triangle-left"
      ></div>
      <div
        class="bg-triangle bg-triangle-dark bg-triangle-bottom bg-triangle-right"
      ></div>

      <!-- Content -->
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6">
            <!-- Heading -->
            <h2 class="text-center mb-4">
              Take advantage of the data
            </h2>
          </div>
        </div>
        <!-- / .row -->
        <div class="row align-items-center">
          <div class="col-md-4 col-lg-4">
            <!-- Image -->
            <div
              class="text-left itemtile"
              v-for="item in msg.left"
              :key="item"
            >
              <!-- Icon -->
              <div class="text-primary mb-4">
                <img class="img-small" :src="item.img" />
              </div>

              <!-- Heading -->
              <h4 class="mb-3">
                {{ item.title }}
              </h4>

              <!-- Content -->
              <p class="text-muted mb-0">
                {{ item.text }}
              </p>
            </div>
          </div>
          <div class="col-md-4 col-lg-4">
            <!-- Image -->
            <div
              class="text-center itemtile"
              v-for="item in msg.center"
              :key="item"
            >
              <!-- Icon -->
              <div class="text-primary mb-4">
                <img class="img-small" :src="item.img" />
              </div>

              <!-- Heading -->
              <h4 class="mb-3">
                {{ item.title }}
              </h4>

              <!-- Content -->
              <p class="text-muted mb-0">
                {{ item.text }}
              </p>
            </div>
          </div>
          <div class="col-md-4 col-lg-4">
            <!-- Image -->
            <div
              class="text-right itemtile"
              v-for="item in msg.right"
              :key="item"
            >
              <!-- Icon -->
              <div class="text-primary mb-4">
                <img class="img-small" :src="item.img" />
              </div>

              <!-- Heading -->
              <h4 class="mb-3">
                {{ item.title }}
              </h4>

              <!-- Content -->
              <p class="text-muted mb-0">
                {{ item.text }}
              </p>
            </div>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>

    <!-- DATA
  ================================================== -->
    <section class="section bg-light">
      <!-- Line -->
      <div class="line line-top"></div>

      <!-- Triangles -->

      <!-- Content -->
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6">
            <h2 class="text-center mb-4">
              Data
            </h2>

            <!-- Subheading -->
            <p class="text-muted text-center mb-5">
              We connect the data coming from multiple sources. The list is growing.
            </p>
          </div>
        </div>
        <!-- / .row -->
        <div class="row">
          <div class="col-md-3" v-for="img in companiesImages" :key="img">
            <!-- Item -->
            <a class="card border-0 mb-3 mb-md-0">
              <!-- Image -->
              <div class="card-img-brand">
                <div class="card-img-brand-innerbox">
                  <img
                    :src="img"
                    alt="App landing"
                    class="card-img-brand-img"
                  />
                </div>
              </div>
            </a>
            <!-- / .card -->
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </section>

  </div>
</template>

<script>
export default {
  name: "APR",
  data() {
    return {
      companies: [
        "logo_euro.svg",
        "logo_mediaexpert.svg",
        "logo_mediamarkt.svg",
        "logo_neonet.svg"
      ],
      msg: {
        left: [
          {
            img: require("../assets/np_fluctuation_2582357_F0CD5E.svg"),
            title: "Monitor price changes",
            text: "See how price changes and promotions affect your business."
          },
          {
            img: require("../assets/np_compare_2889489_F0CD5E.svg"),
            title: "Compare Yourself",
            text:
              "Compare your performance and your products against the competition."
          },
          {
            img: require("../assets/np_competitors_3317994_F0CD5E.svg"),
            title: "Get ahead of your competition",
            text: "Identify and analyze your direct competition and competing products. "
          }
        ],
        center: [
          {
            img: require("../assets/np_shark_3583713_F0CD5E.svg"),
            title: "Be the Bigger Fish",
            text: "The Biggest Fish"
          }
        ],
        right: [
          {
            img: require("../assets/np_design_2825693_F0CD5E.svg"),
            title: "Architect your solution",
            text:
              "With the support of our developpers, you will be able to build custom applications and dashboards that would perfectly fit your needs."
          },
          {
            img: require("../assets/np_price_4162662_F0CD5E.svg"),
            title: "Adjust price tags",
            text:
              "Use Artificial Intelligence and Machine Learning to adjust prices. Sell more, earn more."
          },
          {
            img: require("../assets/np_data_629880_F0CD5E.svg"),
            title: "Connect the dots",
            text: "Join data from various sources and create a perfect model for your business."
          }
        ]
      }
    };
  },
  computed: {
    companiesImages() {
      const imgs = [];
      for (let i = 0; i < this.companies.length; i += 1) {
        imgs.push(require("../assets/external/" + this.companies[i]));
      }
      return imgs;
    }
  }
};
</script>

<style>
.img-small {
  width: 25%;
}

.img-brand {
  position: relative;
}

.itemtile {
  margin-bottom: 32px;
}
.card-img-brand-img {
  max-height: 96px;
  max-width: 224px;
}

.card-img-brand-innerbox {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
